import { CognitoUser } from 'amazon-cognito-identity-js';

export const LOADING_USER = 'LOADING_USER';
export const LOADING_USER_SUCCESS = 'LOADING_USER_SUCCESS';
export const LOADING_USER_FAILED = 'LOADING_USER_FAILED';
export const INITIATE_LOGIN = 'INITIATE_LOGIN';
export const INITIATE_LOGIN_SUCCESS = 'INITIATE_LOGIN_SUCCESS';
export const INITIATE_LOGIN_FAILED = 'INITIATE_LOGIN_FAILED';
export const INITIATE_LOGOUT = 'INITIATE_LOGOUT';
export const INITIATE_LOGOUT_SUCCESS = 'INITIATE_LOGOUT_SUCCESS';
export const INITIATE_LOGOUT_FAILED = 'INITIATE_LOGOUT_FAILED';

interface LoadingUserAction {
    type: typeof LOADING_USER;
}

interface LoadingUserSuccessAction {
    type: typeof LOADING_USER_SUCCESS;
    user: CognitoUser;
}

interface LoadingUserFailedAction {
    type: typeof LOADING_USER_FAILED;
}

interface InitiateLoginAction {
    type: typeof INITIATE_LOGIN;
}

interface InitiateLoginSuccessAction {
    type: typeof INITIATE_LOGIN_SUCCESS;
}

interface InitiateLoginFailedAction {
    type: typeof INITIATE_LOGIN_FAILED;
}

interface InitiateLogoutAction {
    type: typeof INITIATE_LOGOUT;
}

interface InitiateLogoutSuccessAction {
    type: typeof INITIATE_LOGOUT_SUCCESS;
}

interface InitiateLogoutFailedAction {
    type: typeof INITIATE_LOGOUT_FAILED;
}

export interface AppState {
    user: CognitoUser | null;
    language: string |'en';
}

export type AppActionTypes =
    LoadingUserAction
    | LoadingUserSuccessAction
    | LoadingUserFailedAction
    | InitiateLoginAction
    | InitiateLoginSuccessAction
    | InitiateLoginFailedAction
    | InitiateLogoutAction
    | InitiateLogoutSuccessAction
    | InitiateLogoutFailedAction;