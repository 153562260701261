export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_SELECTED = 'UPLOAD_FILE_SELECTED';

interface UploadFileAction {
    type: typeof UPLOAD_FILE;
}

interface UploadFileSuccessAction {
    type: typeof UPLOAD_FILE_SUCCESS;
    key: string;
}

interface UploadFileSelectedAction {
    type: typeof UPLOAD_FILE_SELECTED;
    file: File;
}

export interface UploadFileState {
    isUploadingFile: boolean;
    selectedFile: File | null;
    fileKey: string | null;
    language: string |'en';
}

export type UploadActionTypes = UploadFileAction | UploadFileSuccessAction | UploadFileSelectedAction;