import { Action, combineReducers } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { appReducer } from './app';
import { audioFileReducer } from './audio';
import { uploadFileReducer } from './upload';

export const rootReducer = combineReducers({
    appState: appReducer,
    uploadState: uploadFileReducer,
    audioFileState: audioFileReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk<ReturnType = Promise<Action>> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;