import React from "react";
//import responsiveTheme from '../theme';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { Typography } from "@material-ui/core";
import rfm_arch from './rfm_arch.png';
import rfm_arch_small from './rfm_arch_small.png';
import { Component, Fragment } from 'react';
//import Imgix from "react-imgix";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaLinkedin,FaTwitterSquare } from "react-icons/fa";
import { IconContext } from 'react-icons';

const divStyle= {
  padding: '5px'

};


const styles = (theme: Theme) => createStyles({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0, 0, 2)
  },
  heroButtons: {
    marginTop: theme.spacing(4),
    textTransform: 'none'
  },
  center:{
    textAlign: 'center'
  },
  resultContent:{
    backgroundColor: '#f1f3f4'
  },
  aboutContent:
  {
    padding: theme.spacing(2,2,2)
  }

});


interface AboutProps {
  classes: any ;
}

class About extends Component<AboutProps> {

  render(){
  const { classes } = this.props;
  return (
    <div className={classes.aboutContent}>
      <div className={classes.heroContent}>

      <div className="bg-light">
        <div className="container py-6">
          <div className="row h-100 align-items-center py-5">
            <div className="col-lg-6">
              <h1 className="display-4">Read For Me</h1>
              <br></br>
               <p className="lead text-muted mb-0">Read For Me launched at the 2021 AWS re:Invent Builders’ Fair in Las Vegas. A web application which helps the visually impaired ‘hear documents. With the help of AI services such as Amazon Textract, Amazon Comprehend, Amazon Translate and Amazon Polly utilizing an event-driven architecture and serverless technology, users upload a picture of a document, or anything with text, and within a few seconds “hear” that document in their chosen language.</p>
               <br></br>
            </div>
            <div className="col-lg-3 offset-lg-2">
              <a  href={rfm_arch}><img  alt="" className="img-fluid" src={rfm_arch_small}/></a>

            </div>
        </div>
        </div>
      </div>
<br></br>
      <div className="bg-light py-5">
  <div className="container py-5">
    <div className="row mb-4">
      <div className="col-lg-5">
        <h2 className="display-4 font-weight-light">Our team</h2>
      </div>
    </div>

    <div className="row text-center">
      {/* <!-- Team item--> */}
      <div className="col-xl-3 col-sm-6 mb-5">
        <div className="bg-white rounded shadow-sm py-5 px-4"><img src="https://media-exp2.licdn.com/dms/image/C5603AQFjmME4ZGN-Vg/profile-displayphoto-shrink_200_200/0/1656032171240?e=2147483647&v=beta&t=jj3hiCYgCiIKnzqc5QI-VWtzHluXyo28Zn18RdGCXsc" alt="" width="100" className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"/>
          <h5 className="mb-0">Jack Marcheti</h5><span className="small text-uppercase text-muted">Sr. Solutions Architect</span>
          <div className="mb-0 mt-3">
            <IconContext.Provider value={{ size: "2rem" }} >
              <a  href="https://www.linkedin.com/in/jackmarchetti/"> <FaLinkedin color="#0e76a8"  /></a>
              {/* <a  href="#"> <FaTwitterSquare color="#00acee"  /></a> */}
          </IconContext.Provider>           
          </div>
        </div>
      </div>
      {/* <!-- End--> */}

            {/* <!-- Team item--> */}
            <div className="col-xl-3 col-sm-6 mb-5">
        <div className="bg-white rounded shadow-sm py-5 px-4"><img src="https://media-exp1.licdn.com/dms/image/C4D03AQFNJ-JMW6KNWg/profile-displayphoto-shrink_200_200/0/1516958702755?e=2147483647&v=beta&t=n4wy3fWCn6J8S3NhSUrU96FrBzYg7mMh3f98BSRRUqU" alt="" width="100" className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"/>
          <h5 className="mb-0">Alak Esweradass</h5><span className="small text-uppercase text-muted">Sr. Solutions Architect</span>
          <div className="mb-0 mt-3">
            <IconContext.Provider value={{ size: "2rem" }} >
              <a  href="https://www.linkedin.com/in/alaknantha/"> <FaLinkedin color="#0e76a8"  /></a>
              {/* <a  href="#"> <FaTwitterSquare color="#00acee"  /></a> */}
          </IconContext.Provider>           
          </div>
        </div>
      </div>
      {/* <!-- End--> */}

            {/* <!-- Team item--> */}
            <div className="col-xl-3 col-sm-6 mb-5">
        <div className="bg-white rounded shadow-sm py-5 px-4"><img src="https://static-exp1.licdn.com/sc/h/244xhbkr7g40x6bsu4gi6q4ry" alt="" width="100" className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"/>
          <h5 className="mb-0">Swagat Kulkarni</h5><span className="small text-uppercase text-muted">Solutions Architect</span>
          <div className="mb-0 mt-3">
            <IconContext.Provider value={{ size: "2rem" }} >
              <a  href="https://www.linkedin.com/in/swagat-kulkarni-1a358a3/"> <FaLinkedin color="#0e76a8"  /></a>
              {/* <a  href="#"> <FaTwitterSquare color="#00acee"  /></a> */}
          </IconContext.Provider>           
          </div>
        </div>
      </div>
      {/* <!-- End--> */}

            {/* <!-- Team item--> */}
            <div className="col-xl-3 col-sm-6 mb-5">
        <div className="bg-white rounded shadow-sm py-5 px-4"><img src="https://media-exp1.licdn.com/dms/image/C4D03AQGnjf9ob-pabg/profile-displayphoto-shrink_200_200/0/1622576747086?e=2147483647&v=beta&t=sUyYCWMCWwJmPUJljmOE1EqS_BDyGIeVLeFms9KVlWA" alt="" width="100" className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"/>
          <h5 className="mb-0">Trac Do</h5><span className="small text-uppercase text-muted">Solutions Architect</span>
          <div className="mb-0 mt-3">
            <IconContext.Provider value={{ size: "2rem" }} >
              <a  href="https://www.linkedin.com/in/tracdo/"> <FaLinkedin color="#0e76a8"  /></a>
              {/* <a  href="#"> <FaTwitterSquare color="#00acee"  /></a> */}
          </IconContext.Provider>           
          </div>
        </div>
      </div>
      {/* <!-- End--> */}

      {/* <!-- Team item--> */}
      <div className="col-xl-3 col-sm-6 mb-5">
        <div className="bg-white rounded shadow-sm py-5 px-4"><img src="https://media-exp1.licdn.com/dms/image/C4E03AQGnQ_WewCQypA/profile-displayphoto-shrink_200_200/0/1554256182153?e=2147483647&v=beta&t=VLLzptm80jO3frdMk8pxL0OWCWUXj3VIk0t8yuZfbwE" alt="" width="100" className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"/>
          <h5 className="mb-0">Negin Rouhanizadeh</h5><span className="small text-uppercase text-muted">Solutions Architect</span>
          <div className="mb-0 mt-3">
            <IconContext.Provider value={{ size: "2rem" }} >
              <a  href="https://www.linkedin.com/in/negin-rouhanizadeh-38a5161b"> <FaLinkedin color="#0e76a8"  /></a>
              {/* <a  href="#"> <FaTwitterSquare color="#00acee"  /></a> */}
          </IconContext.Provider>           
          </div>
        </div>
      </div>
      {/* <!-- End--> */}

  
    </div>
  </div>
</div>

        
      </div>
      
    </div>
  );
  }
}

export default withStyles(styles)(About);