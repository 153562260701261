import { AppActionTypes, AppState, LOADING_USER, LOADING_USER_FAILED, LOADING_USER_SUCCESS, INITIATE_LOGIN, INITIATE_LOGOUT } from "./types";

const initialState: AppState = {
    user: null,
    language: ''
}

export const appReducer = (state = initialState, action: AppActionTypes): AppState => {
    switch (action.type) {
        case LOADING_USER:
            return Object.assign({}, state, {
                isLoadingUser: true
            });
        case LOADING_USER_SUCCESS:
            return Object.assign({}, state, {
                isLoadingUser: false,
                user: action.user
            });
        case LOADING_USER_FAILED:
            return Object.assign({}, state, {
                isLoadingUser: false,
                user: null
            });
        case INITIATE_LOGIN:
        case INITIATE_LOGOUT:
            return Object.assign({}, state, {
                isLoadingUser: true,
                user: null
            });
        default:
            return state;
    }
}