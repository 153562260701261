import { connect, ConnectedProps } from "react-redux";
import { AppComponent } from "../components";
import { RootState } from "../../store";

const mapStateToProps = (state: RootState) => ({
})

const mapDispatchToProps = {
}

const connector = connect(mapStateToProps, mapDispatchToProps);

interface AppComponentStyleProps {
    classes: any;
}

export type AppProps = ConnectedProps<typeof connector> & AppComponentStyleProps;

export default connector(AppComponent);