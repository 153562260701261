import { connect, ConnectedProps } from "react-redux";
import { UploadComponent } from "../components";
import { RootState } from "../../store";
import { updateFileSelectedThunk, uploadFileAsync } from "../actions";
import { String } from "aws-sdk/clients/cloudsearchdomain";

const mapStateToProps = (state: RootState) => ({
    selectedFile: state.uploadState.selectedFile,
    key: state.uploadState.fileKey,
    isUploading: state.uploadState.isUploadingFile
})

const mapDispatchToProps = {
    //uploadFile: (file: File, language: String, analyzeID:number = 0, isPrivacy: boolean = false) => (uploadFileAsync(file, language, analyzeID,isPrivacy)),
    uploadFile: (file: any, language: String, analyzeID:number = 0, isPrivacy: boolean = false) => (uploadFileAsync(file, language, analyzeID,isPrivacy)),
    updateFileSelected: (file: File) => (updateFileSelectedThunk(file)),
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface UploadComponentStyleProps {
    classes: any;
    language: string;
    analyzeId: number;
    isPrivacy: boolean;
}

export type UploadProps = ConnectedProps<typeof connector> & UploadComponentStyleProps;

export default connector(UploadComponent);