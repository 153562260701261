import { AudioFileActionTypes, AudioFileState, CREATE_AUDIO_FILE, CREATE_AUDIO_FILE_SUCCESS } from "./types";

const initialState: AudioFileState = {
    isGeneratingAudioFile: false,
    language: ''
}

export const audioFileReducer = (state = initialState, action: AudioFileActionTypes): AudioFileState => {
    switch (action.type) {
        case CREATE_AUDIO_FILE:
            return Object.assign({}, state, {
                isGeneratingAudioFile: true,
                response: null
            });
        case CREATE_AUDIO_FILE_SUCCESS:
            return Object.assign({}, state, {
                isGeneratingAudioFile: false,
                response: action.response,
            });
        default:
            return state;
    }
}