import { createTheme,responsiveFontSizes } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: '#153165',
      light: '#106cb',
      dark: '#c66a00'
    },
    secondary: {
      main: '#106cbc',
      light: '#9be7ff',
      dark: '#2286c3'
    },
    error: {
      main: '#ff1900',
    },
    text:{
      primary: '#000',
      secondary: '#fff'
    },

    background: {
      paper: "#fff",
      default: '#fff',
    },
  },
  typography: {
    fontFamily: [
      'Amazon Ember'
    ].join(','),
    h1: {
      fontSize: '64px',
      fontWeight: 'bold',
      textTransform: 'uppercase'
    },

    h3: {
      fontSize: '28px',
    },
    h4: {
      fontSize: '24px'
    },
    h5: {
      fontSize: '10px',
      fontWeight: 'bold',
      textTransform: 'uppercase'
    },
    button:{
      fontSize: '26px',
      fontWeight: 'bold',
      textTransform: 'none'
    }
  },
});

export const themeResponsive = responsiveFontSizes(theme);