import Container from '@material-ui/core/Container';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Component, Fragment } from 'react';
import { AudioPlayerComponent } from '../../audio';
import { UploadComponent } from '../../upload';
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem';
import { AnalyzeIDProps } from '../containers';
import { NumberSetAttributeValue } from 'aws-sdk/clients/dynamodbstreams';

interface AnalyzeIDState {
  language: string;
  languageFriendly: string;
  analyzeID: number;
  isPrivacy: boolean;
}


const styles = (theme: Theme) => createStyles({
  rfmDropDown: {
    padding: '15px',
    width: '320px',
    borderBottom: '',
    border: '1px solid #495474',
    borderRadius: '12px'
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0, 0, 2)
  },
  heroButtons: {
    marginTop: theme.spacing(4),
    textTransform: 'none'
  },
  center:{
    paddingTop: '15px',
    textAlign: 'center'
  },
  resultContent:{
    backgroundColor: '#f1f3f4'
  }
});

class AnalyzeIDComponent extends Component<AnalyzeIDProps, AnalyzeIDState> {

  constructor(props: AnalyzeIDProps) {
    super(props);
    this.state = {
      language: "en",
      languageFriendly: 'English - US',
      analyzeID:1,
      isPrivacy: false
    }
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.handleAnalyzeIDChange = this.handleAnalyzeIDChange.bind(this);
  }

  handleLanguageChange(e:any){
    this.setState({ language: e.target.value, languageFriendly: e.currentTarget.innerText })
  };

  handleAnalyzeIDChange(e:any){
    this.setState({analyzeID: e.target.value});
  }

  render() {

    const { classes } = this.props;
    const languageProp = {
      language: this.state.language
    }

    return (
      <Fragment>
        <main>
          <div className={classes.heroContent}>
            <Container maxWidth="sm">   
                <Fragment>
                  <Typography variant="h3" align="center" color="textPrimary" paragraph>
                  Take a photo of your state ID or driver's license, select a question, and within a couple of seconds, hear it in your chosen language.
                  </Typography>
                  <div className={classes.center}>
                    <Typography variant='h4'>Tell me:</Typography>
                    <Select value={this.state.analyzeID} classes= {{select: classes.rfmDropDown}} disableUnderline={true} onChange={this.handleAnalyzeIDChange}>
                      <MenuItem selected value="1">
                        <Typography variant='h3'>What is my ID number?</Typography></MenuItem>
                      <MenuItem value="2"><Typography variant='h3'>What is my expiration date?</Typography></MenuItem>
                      <MenuItem value="3"><Typography variant='h3'>When was my ID issued?</Typography></MenuItem>
                    </Select>
                  </div>
                  <div className={classes.center}>
                    <Typography variant='h4'>Read for me in :</Typography>
                    <Select value={this.state.language} classes= {{select: classes.rfmDropDown}} disableUnderline={true} onChange={this.handleLanguageChange}>
                    <MenuItem selected value="en"><Typography variant='h3'>English - US</Typography></MenuItem>
                      <MenuItem value="en-GB"><Typography variant='h3'>English - UK</Typography></MenuItem>
                      <MenuItem value="en-AU"><Typography variant='h3'>English - Australia</Typography></MenuItem>
                      <MenuItem value="en-ZA"><Typography variant='h3'>English - South Africa</Typography></MenuItem>
                      <MenuItem value="en-NZ"><Typography variant='h3'>English - New Zealand</Typography></MenuItem>
                      <MenuItem value="es"><Typography variant='h3'>Spanish - US</Typography></MenuItem>
                      <MenuItem value="es-EU"><Typography variant='h3'>Spanish - European</Typography></MenuItem>
                      <MenuItem value="fr"><Typography variant='h3'>French - Canadian</Typography></MenuItem>
                      <MenuItem value="fr-FR"><Typography variant='h3'>French</Typography></MenuItem>
                      <MenuItem value="it"><Typography variant='h3'>Italian</Typography></MenuItem>
                      <MenuItem value="de"><Typography variant='h3'>German</Typography></MenuItem>
                      <MenuItem value="ko"><Typography variant='h3'>Korean</Typography></MenuItem>
                      <MenuItem value="pt"><Typography variant='h3'>Portugese</Typography></MenuItem>
                    </Select>
                  </div>
                  <div className={classes.heroButtons}>
                    <UploadComponent language={this.state.language} analyzeId={this.state.analyzeID} isPrivacy={this.state.isPrivacy}/>
                  </div>
                </Fragment>
                <div className={classes.resultContent}>   
                  <AudioPlayerComponent language = {this.state.language} languageFriendly={this.state.languageFriendly} />
                </div>       
            </Container>
          </div>
        </main>
      </Fragment >
    );
  }
}

export default withStyles(styles)(AnalyzeIDComponent);
