import { Satellite, SaveAltTwoTone } from "@material-ui/icons";
import axios from "axios";
import { AppThunk } from "../store";
import { AudioFileActionTypes, AudioResponse, CREATE_AUDIO_FILE, CREATE_AUDIO_FILE_SUCCESS } from "./types"


export function createAudioFile(): AudioFileActionTypes {
    return {
        type: CREATE_AUDIO_FILE
    }
}

export function createAudioFileSuccess(response: AudioResponse): AudioFileActionTypes {
    return {
        type: CREATE_AUDIO_FILE_SUCCESS,
        response
    }
}

function getLanguageFromCode(code:string){
  var language = '';
  switch (code){
    case 'en':
      language =  "English";
      break;
    case 'es': 
      language =  "Spanish";
      break;
    case 'fr':
      language =  "French";
      break;
    case 'de':
      language =  "German";
      break;
    case 'ko':
      language =  "Korean";
      break;
    case 'pt':
      language =  "Portugese";
      break;
    case 'it':
      language =  "Italian";
      break;
    default:
      language =  "Unknown";
      break;
  }
  return language;

}

export const createAudioFileThunk = (key: String, language: String, analyze_id: number, isPrivacy:boolean): AppThunk => async (dispatch, getState) => {
    dispatch(createAudioFile());

    const state = getState();

    var postResult = await axios.post<any>(
      "https://4ytxb5a2i3.execute-api.us-east-1.amazonaws.com/v1/process-sync",
      {
        "s3_key": key,
        "target_language" : language,
        "analyzeid_type": "" + analyze_id,
        "privacy": isPrivacy
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": state.appState.user?.getSignInUserSession()?.getIdToken().getJwtToken()
        }
      });

    const result: AudioResponse = {
      translatedText : postResult.data.translated_text,
      extractedText: postResult.data.extracted_text,
      audioFileUrl: postResult.data.audio_file_url,
      originalLanguage : getLanguageFromCode(postResult.data.source_language)
    }

    return dispatch(createAudioFileSuccess(result));
}