import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { UploadProps } from '../containers/Upload';
import { ClipLoader } from 'react-spinners';
import { createStyles, Grid, Theme, withStyles } from '@material-ui/core';
import Resizer from "react-image-file-resizer";

const resizeFile = (file:File) =>
  new Promise<string>((resolve) => {
    Resizer.imageFileResizer(
      file,
      4032,
      3024,
      "JPEG",
      60,
      0,
      (uri:any) => {
        resolve(uri);
      },
      "base64"
    );
  });

const styles = (theme: Theme) => createStyles({
  fileDetails: {
    padding: theme.spacing(1, 0, 1)
  },
  uploadButton: {
    borderRadius: '17px',
    padding: '10px',
    width: '320px'
  }
});

class UploadComponent extends Component<UploadProps> {
  constructor(props: UploadProps) {
    super(props);

    this.onFileChange = this.onFileChange.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }

  async onFileChange (event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.item(0);
    if (!!file) {
      await this.props.updateFileSelected(file);
      await this.uploadFile();
    }
  }

  async uploadFile() {
    const selectedFile = this.props.selectedFile;
    const language = this.props.language;
    const analyzeID = this.props.analyzeId;
    const isPrivacy = this.props.isPrivacy;
    if (!selectedFile) {
      return;
    }

    const imageStream =  await resizeFile(selectedFile);
    await this.props.uploadFile(imageStream, language, analyzeID, isPrivacy);
    //await this.props.uploadFile(selectedFile, language, analyzeID, isPrivacy);
  };

  render() {
    const { classes } = this.props;

    if (this.props.isUploading) {
      return (
        <Grid container spacing={0} justifyContent="center" direction="column" alignItems="center" className={classes.fileDetails}>
          <Grid item>
            <ClipLoader loading={this.props.isUploading} />
          </Grid>
        </Grid>
      )
    }

    return (
      <Grid container spacing={0} justifyContent="center" direction="column" alignItems="center" className={classes.fileDetails}>
        <Grid item>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="image-file"
            type="file"
            onChange={this.onFileChange}
          />
          <label htmlFor="image-file">
            <Button className={classes.uploadButton} variant="contained" component="span" color="secondary"> 
              <Typography variant="button" >Take or Select Photo</Typography>
            </Button>
          </label>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(UploadComponent);