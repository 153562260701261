import Container from '@material-ui/core/Container';
import { createStyles, Theme, withTheme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Component, Fragment } from 'react';
import { AudioPlayerComponent } from '../../audio';
import { UploadComponent } from '../../upload';
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import { AppProps } from '../containers';
import { FormControlLabel, FormLabel, Tooltip } from '@material-ui/core';
import { FaInfoCircle } from "react-icons/fa";
import ReactTooltip from "react-tooltip";

interface AppState {
  language: string;
  languageFriendly: string;
  isPrivacy: boolean;
}

const styles = (theme: Theme) => createStyles({
  rfmDropDown: {
    padding: '15px',
    width: '320px',
    borderBottom: '',
    border: '1px solid #495474',
    borderRadius: '12px'
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0, 0, 2),
    
  },
  heroButtons: {
    marginTop: theme.spacing(4),
    textTransform: 'none'
  },
  center:{
    textAlign: 'center'
  },
  checkBox:{
    color: theme.palette.primary.main
  },
  resultContent:{
    backgroundColor: '#f1f3f4'
  }
});

class AppComponent extends Component<AppProps, AppState> {

  constructor(props: AppProps) {
    super(props);
    this.state = {
      language: "en",
      languageFriendly: 'English - US',
      isPrivacy: false
    }
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.handlePrivacyChange = this.handlePrivacyChange.bind(this);
  }

  handleLanguageChange(e:any){
    this.setState({ language: e.target.value, languageFriendly: e.currentTarget.innerText })
  };

  handlePrivacyChange(e:any){
    this.setState({isPrivacy: e.target.checked});
  }


  render() {

    const { classes } = this.props;

    return (
      <Fragment>
        <main>
          <div className={classes.heroContent}>
            <Container maxWidth="sm">   
                <Fragment>
                  <Typography variant="h3" align="center" color="textPrimary" paragraph>
                  Take a photo of anything with text and within a couple of seconds you’ll be able to hear it in your chosen language.
                  </Typography>
                  <div className={classes.center}>
                    <Typography variant='h4'>Read for me in :</Typography>
                    <Select value={this.state.language} classes= {{select: classes.rfmDropDown}} disableUnderline={true} onChange={this.handleLanguageChange}>
                      <MenuItem selected value="en">
                        <Typography variant='h3'>English - US</Typography></MenuItem>
                      <MenuItem value="en-GB"><Typography variant='h3'>English - UK</Typography></MenuItem>
                      <MenuItem value="en-AU"><Typography variant='h3'>English - Australia</Typography></MenuItem>
                      <MenuItem value="en-ZA"><Typography variant='h3'>English - South Africa</Typography></MenuItem>
                      <MenuItem value="en-NZ"><Typography variant='h3'>English - New Zealand</Typography></MenuItem>
                      <MenuItem value="es"><Typography variant='h3'>Spanish - US</Typography></MenuItem>
                      <MenuItem value="es-EU"><Typography variant='h3'>Spanish - European</Typography></MenuItem>
                      <MenuItem value="fr"><Typography variant='h3'>French - Canadian</Typography></MenuItem>
                      <MenuItem value="fr-FR"><Typography variant='h3'>French</Typography></MenuItem>
                      <MenuItem value="it"><Typography variant='h3'>Italian</Typography></MenuItem>
                      <MenuItem value="de"><Typography variant='h3'>German</Typography></MenuItem>
                      <MenuItem value="ko"><Typography variant='h3'>Korean</Typography></MenuItem>
                      <MenuItem value="pt"><Typography variant='h3'>Portugese</Typography></MenuItem>
                    </Select>
                  </div>
                  <div className={classes.center}>
                    
                    <Tooltip title= {<Typography variant='h5'>Enable privacy to whisper sensitive information like social security, or credit card numbers</Typography>}>
                      <FormControlLabel 
                      control = {<Switch className={classes.checkBox} checked={this.state.isPrivacy} onChange={this.handlePrivacyChange} />}
                      label ={<Typography variant='h4'>Privacy</Typography>}
                      />
                    </Tooltip>

                    <FaInfoCircle  color="#0e76a8" data-tip data-for='privacyTip' />
                    <ReactTooltip id="privacyTip" place="right" effect="solid">
                    Enable privacy to whisper sensitive information like social security, or credit card numbers
                     </ReactTooltip>

                  </div>
                  <div className={classes.heroButtons}>
                    <UploadComponent language={this.state.language} analyzeId = {0} isPrivacy={this.state.isPrivacy}/>
                  </div>
                </Fragment>
                <div className={classes.resultContent}>   
                  <AudioPlayerComponent language = {this.state.language} languageFriendly={this.state.languageFriendly} />
                </div>       
            </Container>
          </div>
        </main>
      </Fragment >
    );
  }
}

export default withStyles(styles, {withTheme: true})(AppComponent);
