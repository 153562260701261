import { UploadActionTypes, UploadFileState, UPLOAD_FILE, UPLOAD_FILE_SELECTED, UPLOAD_FILE_SUCCESS } from "./types";

const initialState: UploadFileState = {
    isUploadingFile: false,
    selectedFile: null,
    fileKey: null,
    language: ''
}

export const uploadFileReducer = (state = initialState, action: UploadActionTypes): UploadFileState => {
    switch (action.type) {
        case UPLOAD_FILE:
            return Object.assign({}, state, {
                //isUploadingFile: true
            });
        case UPLOAD_FILE_SUCCESS:
            return Object.assign({}, state, {
                isUploadingFile: false,
                selectedFile: null,
                fileKey: action.key,
              
            });
        case UPLOAD_FILE_SELECTED:
            return Object.assign({}, state, {
                isUploadingFile: true,
                selectedFile: action.file
            })
        default:
            return state;
    }
}