import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import { createStyles, makeStyles, Theme, withStyles, withTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import { Component, Fragment } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import NotesIcon from '@material-ui/icons/Notes';
import {Link} from "react-router-dom";
import { MenuProps } from '../containers';
import { themeResponsive } from '../../theme';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import AssignmentInd from '@material-ui/icons/AssignmentInd';

const styles = (theme: Theme) => createStyles({
  arrowDown:{ 
    width: '100%',
    height: '48px',
    marginTop: '-17px'
  },
  rfmHeader:{
    top: '0px',
    left: '0px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100px',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    textAlign: 'center',
    verticalAlign:'center',
    opacity: "1",
  },
  });
  
interface MenuState {
  anchorEl: any | null;
}

class MenuComponent extends Component <MenuProps, MenuState> {

  constructor(props: MenuProps) {
    super(props);
    this.state = {
      anchorEl: null
    }
    this.handleMenu = this.handleMenu.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }


  handleMenu(event: React.MouseEvent<HTMLElement>) {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose() {
    this.setState({ anchorEl: null });
  };

  render() {
    const {classes} = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const title = window.location.pathname.startsWith("/analyze") ? "Analyze ID": "Read for me";

    return (
      <Fragment>
            <AppBar position="relative">
            <Toolbar>
                <div>
                  <IconButton
                    aria-label="menu button"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={this.handleMenu}
                    color="inherit">
                    <MenuIcon />
                  </IconButton>
                  <Menu onBlur = {this.handleClose}
                    id="menu-appbar"
                    autoFocus = {true}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted= {false}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={open}
                    onClose={this.handleClose}
                  >
                    <MenuItem component ={Link} to ='/'>
                      <ListItemIcon>
                        <RecordVoiceOverIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit" noWrap>
                        Home
                      </Typography>
                    </MenuItem>
                    <MenuItem component ={Link} to ='/analyze'>
                      <ListItemIcon>
                        <AssignmentInd fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit" noWrap>
                        Analyze ID
                      </Typography>
                    </MenuItem>
                    <MenuItem component ={Link} to ='/about'>
                      <ListItemIcon>
                      <NotesIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit" noWrap>
                        About
                      </Typography>
                    </MenuItem>
                  </Menu>
                </div>
            </Toolbar>
          </AppBar>
          <div className={classes.rfmHeader}>
            <Typography variant="h1" align="center" color="textSecondary" >{title}</Typography>
          </div>
          <svg className={classes.arrowDown} viewBox="0 0 32 32"  aria-hidden="true"><path fill={themeResponsive.palette.primary.main} d="M24 11.305l-7.997 11.39L8 11.305z"/></svg>
      </Fragment >
    );
  }
}

export default withStyles(styles)(MenuComponent);