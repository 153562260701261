import React from 'react';
import ReactDOM from 'react-dom';
import { AppComponent } from './app';
import { MenuComponent } from './menu/components';
import { About } from './about';
import reportWebVitals from './reportWebVitals';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { themeResponsive } from './theme';
import { applyMiddleware, createStore } from 'redux';
import { rootReducer } from './store';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Amplify from 'aws-amplify';
import { AnalyzeIDComponent } from './analyze_id/components';

const loggerMiddleware = createLogger();

const store = createStore(rootReducer, applyMiddleware(
  thunkMiddleware,
  loggerMiddleware
));

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === "[::1]" ||
  // 127.0.0.1/8 is considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);

export const CognitoClientId = isLocalhost ? '6l5mijidrpbqlphf4smrr8jniq' : '23alaep13pmke8hdct49vut36m';
export const CognitoDomainUrl = 'readformeuserpool.auth.us-east-1.amazoncognito.com';
const currentPath = window.location.pathname;
const title = currentPath.startsWith("/analyze") ? "Analyze ID": "Read for me";

Amplify.configure({
  Auth: {
    region: "us-east-1",
    userPoolId: 'us-east-1_xuizFmbDw',
    userPoolWebClientId: CognitoClientId,
    cookieStorage: {
      domain: isLocalhost ? 'localhost' : 'master.d31i7cps1mqx2l.amplifyapp.com',
      path: '/',
      expires: 365,
      secure: !isLocalhost
    }
  },
  oauth: {
    domain: CognitoDomainUrl,
    scope: ['profile', 'openid'],
    redirectSignIn: isLocalhost ? 'http://localhost:3000' : 'https://master.d31i7cps1mqx2l.amplifyapp.com',
    redirectSignOut: isLocalhost ? 'http://localhost:3000' : 'https://master.d31i7cps1mqx2l.amplifyapp.com',
    responseType: 'code'
  }
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={themeResponsive}>
      <Provider store={store}>
        <CssBaseline />
        <BrowserRouter forceRefresh={true}>
          <MenuComponent></MenuComponent>
          <Switch>
            <Route exact path="/" component= {AppComponent}>
            </Route>
            <Route path ='/about' component = {About} >
              </Route>
            <Route path ='/analyze' component = {AnalyzeIDComponent} >
              </Route>
          </Switch>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);