import AWS, { CognitoIdentityCredentials } from "aws-sdk";
import S3 from "aws-sdk/clients/s3";
import { AppThunk } from "../store";
import { UploadActionTypes, UPLOAD_FILE, UPLOAD_FILE_SELECTED, UPLOAD_FILE_SUCCESS } from "./types";
import { v4 as uuidv4 } from 'uuid';
import { createAudioFileThunk } from "../audio";

export function uploadFile(): UploadActionTypes {
    return {
        type: UPLOAD_FILE
    }
}

export function uploadFileSuccess(key: string): UploadActionTypes {
    return {
        type: UPLOAD_FILE_SUCCESS,
        key: key
    }
}

export function updateFileSelected(file: File): UploadActionTypes {
    return {
        type: UPLOAD_FILE_SELECTED,
        file: file
    }
}

export const updateFileSelectedThunk = (file: File): AppThunk => async dispatch => {
    return dispatch(updateFileSelected(file));
}
// *** NEED TO CLEAN UP
//export const uploadFileAsync = (file: File, language:string, analyzeID: number, isPrivacy: boolean): AppThunk => async dispatch => {
export const uploadFileAsync = (file: any, language:string, analyzeID: number, isPrivacy: boolean): AppThunk => async dispatch => {
    dispatch(uploadFile());

    const bucketName = "read-for-me-demo";
    const bucketRegion = "us-east-1";
    const identityPoolId = "us-east-1:1f770e4f-a193-4d20-843d-0d7c8ed3aabf";

    // const bucketName = process.env.REACT_APP_S3_BUCKET;
    // const bucketRegion = process.env.REACT_APP_S3_REGION;
    // const identityPoolId = process.env.REACT_APP_IDENT_POOL_ID;

    AWS.config.update({
        region: bucketRegion,
        useAccelerateEndpoint: true,
        credentials: new CognitoIdentityCredentials({
          IdentityPoolId: identityPoolId
        })
    })

    const s3 = new S3({
      apiVersion: "2006-03-01",
      params: { Bucket: bucketName },
    });

    var fileName = uuidv4() + '.jpg';
    console.log(fileName);

    var photoKey = "image/" + fileName;
    const fileString = file as string;
    const fileBytes = Buffer.from(fileString.replace(/^data:image\/\w+;base64,/, ""),'base64')
    //const fileBytes = await file.arrayBuffer();
    const result = await s3.upload({
      Bucket: bucketName,
      Key: photoKey,
      ContentEncoding: 'base64',
      Body: fileBytes
    }).promise();

    await dispatch(createAudioFileThunk(result.Key, language, analyzeID,isPrivacy));

    return dispatch(
        uploadFileSuccess(result.Key)
    );
}