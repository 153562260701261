import { connect, ConnectedProps } from "react-redux";
import { AudioPlayerComponent } from "../components";
import { RootState } from "../../store";

const mapStateToProps = (state: RootState) => ({
    response: state.audioFileState.response,
})

const mapDispatchToProps = {
    
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface AudioPlayerStyleProps {
    classes: any;
    language: string;
    languageFriendly: string;
}

export type AudioPlayerProps = ConnectedProps<typeof connector> & AudioPlayerStyleProps;

export default connector(AudioPlayerComponent);