import {Box, Tabs, Tab} from "@material-ui/core";
import { Component } from "react";
import { AudioPlayerProps } from "../containers/AudioPlayer";
import AudioPlayer from 'material-ui-audio-player';
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import {PlayArrow, VolumeUp, VolumeOff, Pause,Replay, Close} from '@material-ui/icons';

const styles = (theme: Theme) => createStyles({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    cardMedia: {
        padding: '5%',
        width: "100%",
        justifyContent: "center"
    },
    cardContent: {
    },
});


interface AudioComponentState {
    selectedTab: number
  }

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }


const icons = {
    PlayIcon: PlayArrow,
    ReplayIcon: Replay,
    PauseIcon: Pause,
    VolumeUpIcon: VolumeUp,
    VolumeOffIcon: VolumeOff,
    CloseIcon: Close
  }

class AudioPlayerComponent extends Component<AudioPlayerProps, AudioComponentState> {

    constructor(props: AudioPlayerProps) {
        super(props);
        this.state = {selectedTab :0};
        this.handleChange = this.handleChange.bind(this);
    }

    render() {
        const { classes } = this.props;
     
        if (this.props.response && this.props.response.audioFileUrl) {
          const sameLanguage = this.props.response.translatedText !== '';
            return (
                <Container maxWidth="md">
                        <CardMedia className={classes.cardMedia} >
                            <AudioPlayer
                                src={this.props.response.audioFileUrl}
                                elevation={1}
                                width="100%"
                                variation="primary"
                                rounded = {true}
                                download={false}
                                order="standart"
                                loop={false}
                                icons = {icons}
                            />
                        </CardMedia>
                        { sameLanguage 
                        ? <CardMedia>
                            <Tabs value={this.state.selectedTab} onChange={this.handleChange}>
                                <Tab label = {this.props.languageFriendly} {...a11yProps(0)}></Tab>
                                <Tab label = {this.props.response.originalLanguage + ' (Original)'} {...a11yProps(1)}></Tab>
                            </Tabs>
                            <TabPanel value={this.state.selectedTab} index={0}>{this.props.response.translatedText}</TabPanel>
                            <TabPanel value={this.state.selectedTab} index={1}>{this.props.response.extractedText}</TabPanel>
                        
                        </CardMedia>
                        :<CardMedia>
                            <div><Typography variant='h4'>{this.props.response.extractedText}</Typography></div>
                        </CardMedia>
                        }
                </Container>
            )
        }
        return (<Box></Box>)
    }

    handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
      this.setState({selectedTab : newValue})
    };
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default withStyles(styles)(AudioPlayerComponent);